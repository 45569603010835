/* You can add global styles to this file, and also import other style files */
$sgbs-font-path: '~@sg-bootstrap/core/fonts';

@import "@sg-bootstrap/core/dist/css/sg-bootstrap-standard.min.css";
@import "@sg-bootstrap/icons/dist/index.min.css";

/*
 * https://developer.sgmarkets.com/docs/web/aggrid/overview.html
 * Keep the major version of ag-grid-community/enterprise in sync with package.json:
 *      '@sg-bootstrap/ag-grid-theme/dist/XX-standard.css'
 */
 @import "@sg-bootstrap/ag-grid-theme/dist/28-standard-condensed.css";
 @import "@sg-bootstrap/ag-grid-theme/dist/28-standard.css";

